import {
    Checkbox,
    Col,
    Collapse,
    Form,
    FormInstance,
    Input,
    message,
    Pagination,
    Row,
    Select,
    Table
} from "antd";
// tslint:disable-next-line: no-submodule-imports
import { ColumnProps } from "antd/lib/table";

import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import Perm from "../../app/Perm";
import {
    CustomerForListDTO,
    IShippingOrderForListDTO
} from "../../app/WebAPIClients";
import Consts, {
    formItemLayoutForCheckboxGroup,
    formItemLayoutForCol,
    formItemLayoutForSearch,
    SlabExportOptionName
} from "../../components/Consts";
import ErrorHandler from "../../components/ErrorHandler";
import ExportSelector from "../../components/ExportSelector";
import ExportUtil from "../../components/ExportUtil";
import PermCtl from "../../components/PermCtrl";
import SearchButtonGroup from "../../components/SearchButtonGroup";
import { ISalesOrderForListForUI } from "../../components/type";
import Util from "../../components/Util";
import "./PageSOList.styl";
import Store from "./store";

const CheckboxGroup = Checkbox.Group;
const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;

const {
    Customers,
    SalesOrderType,
    SalesOrderStatus,
    ShippingOrderStatus,
    SlabExportOption
} = Consts;

declare interface IPageSOListProps extends RouteComponentProps<{}> {}

declare interface IPageSOListState extends IActionErrorState {
    soExpandedArr: number[];
    customers: CustomerForListDTO[];
}

@observer
class PageSOList extends React.Component<IPageSOListProps, IPageSOListState> {
    private formRef: React.RefObject<FormInstance>;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            soExpandedArr: [],
            customers: []
        };
    }

    public async componentDidMount() {
        const t = this;
        const customers = await Customers.getCustomers();
        t.setState({ customers });
        this.loadData();
    }

    public render(): JSX.Element {
        const t = this;
        const s = t.state;
        const sl = Store.salesOrderList;
        const filterData = Store.filterData;
        const pagingInfo = Store.pagingInfo;

        const checkBoxOptions = Consts.SalesOrderStatus.data.map(status => {
            return { label: status.text, value: status.value.toString() };
        });

        const customerOptions = s.customers.map(c => {
            return (
                <Option key={c.id} value={c.id}>
                    {c.name}
                </Option>
            );
        });

        const orderTypeOptions = SalesOrderType.data.map(status => {
            return (
                <Option
                    key={status.value as string}
                    value={status.value as string}
                >
                    {status.text}
                </Option>
            );
        });

        const columns: Array<ColumnProps<ISalesOrderForListForUI>> = [
            {
                title: "销售订单号",
                dataIndex: "orderNumber",
                key: "orderNumber",
                width: "20%"
            },
            {
                title: "客户名称",
                dataIndex: "customerName",
                key: "customerName",
                width: "15%"
            },
            {
                title: "工程名称",
                dataIndex: "projectName",
                key: "projectName",
                width: "15%"
            },
            {
                title: "销售类型",
                dataIndex: "orderType",
                key: "orderType",
                width: "15%",
                render: type => {
                    return SalesOrderType.getOrderType(type).text;
                }
            },
            {
                title: "状态",
                dataIndex: "status",
                key: "status",
                width: "15%",
                render: status => {
                    return SalesOrderStatus.getStatus(status).text;
                }
            }
        ];

        const exportSlabStyle = PermCtl.isAuthorized(Perm.SO_E)
            ? {
                  marginLeft: "15px",
                  marginTop: "6px",
                  color: "rad"
              }
            : {
                  marginTop: "6px",
                  color: "rad"
              };
        if (PermCtl.isAnyAuthorized([Perm.SO_E, Perm.S_CS_E])) {
            const actionColumns = [
                {
                    title: "操作",
                    key: "action",
                    width: "20%",
                    render: (text, record) => (
                        <div>
                            {PermCtl.isAuthorized(Perm.SO_E) ? (
                                <span>
                                    <a
                                        href="#"
                                        type="primary"
                                        style={{
                                            marginLeft: "2px",
                                            marginTop: "6px"
                                        }}
                                        onClick={t.handlePrintSO.bind(
                                            t,
                                            record,
                                            null
                                        )}
                                    >
                                        导出销售订单
                                    </a>
                                </span>
                            ) : null}
                            {PermCtl.isAuthorized(Perm.S_CS_E) &&
                            [30, 40, 80].includes(record.orderType) ? (
                                <span>
                                    <a
                                        href="#"
                                        type="primary"
                                        style={exportSlabStyle}
                                        onClick={t.handlePrintBundles.bind(
                                            t,
                                            record
                                        )}
                                    >
                                        导出码单
                                    </a>
                                </span>
                            ) : null}
                        </div>
                    )
                }
            ];

            columns.push(...actionColumns);
        }

        return (
            <div>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="高级搜索" key="1">
                        <Form
                            ref={this.formRef}
                            name={"soAdvancedSearchForm"}
                            onFinish={t.handleSearch}
                            {...formItemLayoutForSearch}
                        >
                            <Row>
                                <Col span={19}>
                                    <FormItem
                                        {...formItemLayoutForCheckboxGroup}
                                        label="状态"
                                    >
                                        <CheckboxGroup
                                            options={checkBoxOptions}
                                            value={
                                                filterData.selectedStatusCodes
                                            }
                                            onChange={t.handleSPOStatusCheckBoxChanged.bind(
                                                t
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                {PermCtl.isAuthorized(Perm.S_CS_E) ? (
                                    <Col
                                        span={5}
                                        style={{
                                            textAlign: "right",
                                            marginTop: "10px"
                                        }}
                                    >
                                        <ExportSelector />
                                    </Col>
                                ) : null}
                            </Row>
                            <Row>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="客户名称">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="请选择客户"
                                            value={filterData.customerIds}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "customerIds"
                                            )}
                                        >
                                            {customerOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="销售订单号">
                                        <Input
                                            placeholder="请输入销售订单号"
                                            value={filterData.salesOrderNumber}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "salesOrderNumber"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="订单类型">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="请选择类型"
                                            value={filterData.salesOrderTypes}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "salesOrderTypes"
                                            )}
                                        >
                                            {orderTypeOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                            <SearchButtonGroup
                                onClearClick={t.handleClearSearchCriteria}
                            />
                        </Form>
                    </Panel>
                </Collapse>
                <div>
                    <Table
                        columns={columns}
                        dataSource={sl}
                        pagination={false}
                        rowKey="id"
                        expandedRowRender={
                            PermCtl.isAnyAuthorized([Perm.SPO_R])
                                ? t.showSODetail
                                : null
                        }
                        expandedRowKeys={s.soExpandedArr}
                        onExpand={t.handleRowExpanded}
                    />
                </div>
                {pagingInfo ? (
                    <div className="paginationArea">
                        <Pagination
                            total={pagingInfo.totalRecordCount}
                            // tslint:disable-next-line:jsx-no-lambda
                            showTotal={total => `共 ${total} 个销售订单`}
                            pageSize={pagingInfo.pageSize}
                            current={pagingInfo.currentPage}
                            defaultCurrent={1}
                            showQuickJumper={true}
                            onChange={t.handlePaginationChange}
                            showSizeChanger={false}
                        />
                    </div>
                ) : null}
            </div>
        );
    }

    private async loadData() {
        await Store.loadData();
    }

    private handleSPOStatusCheckBoxChanged(selectedStatusCodes: number[]) {
        Store.updataFilterData({ ...Store.filterData, selectedStatusCodes });
    }

    private getExportOptionValue(optionName: SlabExportOptionName) {
        return SlabExportOption.getSavedExportOptionValue(optionName);
    }

    private handleInputChange(label: string, event) {
        Store.updataFilterData({
            ...Store.filterData,
            [label]: event.target.value
        });
    }

    private handleChange(label: string, value) {
        Store.updataFilterData({ ...Store.filterData, [label]: value });
    }

    private handleSearch = async () => {
        Store.setPageNo(1);
        await this.loadData();
        this.setState({ soExpandedArr: [] });
    };

    private handlePaginationChange = async (page: number) => {
        Store.setPageNo(page);
        await this.loadData();
        this.setState({ soExpandedArr: [] });
    };

    private handleClearSearchCriteria = async () => {
        Store.updataFilterData({});
        Store.setPageNo(1);
        Store.setPageSize(10);
        await this.loadData();
        this.setState({ soExpandedArr: [] });
    };

    private showSODetail = (salesOrder: ISalesOrderForListForUI) => {
        const t = this;
        const shippingOrders = salesOrder.shippingOrders;
        const spoColumns: Array<ColumnProps<IShippingOrderForListDTO>> = [
            {
                title: "装车单编号",
                key: "seq",
                width: "20%",
                render: item => {
                    return item.orderNumber;
                }
            },
            {
                title: "车牌号",
                dataIndex: "licensePlateNumber",
                key: "licensePlateNumber"
            },
            {
                title: "数量",
                dataIndex: "itemsCount",
                key: "itemsCount",
                width: "15%",
                render: itemsCount => {
                    return itemsCount;
                }
            },
            {
                title: "总金额",
                dataIndex: "totalAmount",
                key: "totalAmount",
                width: "15%",
                render: amount => {
                    return "￥" + amount.toFixed(2);
                }
            },
            {
                title: "状态",
                dataIndex: "status",
                key: "status",
                width: "15%",
                render: status => {
                    return ShippingOrderStatus.getStatus(status).text;
                }
            }
        ];

        if (PermCtl.isAnyAuthorized([Perm.SO_E])) {
            const actionColumns = [
                {
                    title: "操作",
                    key: "action",
                    width: "20%",
                    render: (text, record) => (
                        <span>
                            <a
                                href="#"
                                onClick={t.handlePrintSO.bind(
                                    t,
                                    salesOrder,
                                    record.id
                                )}
                            >
                                按装车单导出销售订单
                            </a>
                        </span>
                    )
                }
            ];

            spoColumns.push(...actionColumns);
        }

        return (
            <div>
                {shippingOrders &&
                shippingOrders.length &&
                shippingOrders.length > 0 ? (
                    <Table
                        style={{ padding: "0 32px" }}
                        rowKey="id"
                        columns={spoColumns}
                        dataSource={shippingOrders}
                        pagination={false}
                    />
                ) : (
                    <p className="notice">没有装车单信息</p>
                )}
            </div>
        );
    };

    private handleRowExpanded = (expanded: boolean, salesOrder) => {
        const t = this;
        const s = t.state;
        const id = salesOrder.id;

        if (expanded && salesOrder && !salesOrder.shippingOrders) {
            Store.getShippingOrders(id);
        }

        const expandedArr = Util.updateExpandedArray(
            s.soExpandedArr,
            salesOrder.id,
            expanded
        );
        t.setState({
            soExpandedArr: expandedArr
        });
    };

    private handlePrintSO(salesOrder, shippingOrderId: number) {
        if (!salesOrder) {
            message.info("请选择需要导出的内容！");
            return false;
        }

        if (salesOrder.totalDetailCount === 0) {
            message.info("此订单没有销售明细，不能打印！");
            return false;
        }

        let printAPI = "SalesOrder/Print?";
        printAPI += "salesOrderId=" + salesOrder.id;

        if (shippingOrderId) {
            printAPI += "&shippingOrderId=" + shippingOrderId;
        }

        message.info("正在导出销售订单以供下载并打印...");
        ExportUtil.setExportUrl(printAPI);
    }

    private async handlePrintBundles(salesOrder: ISalesOrderForListForUI) {
        const t = this;
        if (!salesOrder) {
            message.info("请选择需要导出的内容！");
            return false;
        }

        if (salesOrder.totalDetailCount === 0) {
            ErrorHandler.handleErr("此订单没有销售明细，不能打印码单！", null);
            return false;
        }

        const slabSheetType = t.getExportOptionValue(
            SlabExportOptionName.SlabSheetType
        );
        const exportGrade = t.getExportOptionValue(
            SlabExportOptionName.ExportGrade
        );
        const exportNotes = t.getExportOptionValue(
            SlabExportOptionName.ExportNotes
        );
        if (!slabSheetType || !exportGrade || !exportNotes) {
            message.error("请指定码单导出选项!");
            return;
        }
        await Store.getSalesOrderDetails(salesOrder.id);

        if (!Store.hasError) {
            const sodList = Store.salesOrderDetailList;
            const bundleIds = [];
            if (sodList) {
                sodList.forEach(sod => {
                    if (
                        typeof sod.bundleId !== "undefined" &&
                        sod.bundleId !== null
                    ) {
                        bundleIds.push(sod.bundleId);
                    }
                });
            }

            if (bundleIds.length === 0) {
                ErrorHandler.handleErr("销售订单中没有扎数据，请确认", null);
                return;
            }

            let bundleIdList = "";
            bundleIds.forEach(b => {
                bundleIdList += "bundleIds=" + b + "&";
            });
            const bdIds = bundleIdList.substring(0, bundleIdList.length - 1);
            const printAPI =
                "StoneBundle/Print?" +
                bdIds +
                "&type=" +
                slabSheetType +
                "&exportGrade=" +
                exportGrade +
                "&exportNotes=" +
                exportNotes;
            message.info(
                "正在导出销售订单：" + salesOrder.orderNumber + "的码单..."
            );
            ExportUtil.setExportUrl(printAPI);
        }
    }
}

export default PageSOList;
